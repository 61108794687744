import React, { Component } from 'react';

import { RestDataSource } from "../webservice/RestDataSource";

import { BrowserRouter as Router, Link, Route, Redirect } from "react-router-dom";

import Loader from 'react-loader-spinner'

export class DefaultDisplayComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products:[]
        };
        this.productDataSource = new RestDataSource("https://api.afrikpay.com/products/dev");
    }

    render = () =>
        <React.Fragment>
            <div className="jumbotron-fluid bg-heavy-rain mb-4" data-step="4" data-intro="This is the end of the tour!" data-position="top">
                <div className="container">
                    <h1 className="display-4">Products</h1>
                    <p className="lead" id="elem-tour-5">Here you have all products</p>
                </div>
            </div>
            <div className="tab-content">
                <div className="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div className="row">
                        {this.state.products.map( product => {
                            return <div className="col-md-3" key={product.id}>
                                        <div className="main-card mb-3 card">
                                            <div className="card-header card-customize">
                                                <i className={`${product.icons[product.id]} mr-3`}> </i>
                                                {product.name}
                                            </div>
                                            <div className="card-body">
                                                <p>{product.description}</p>
                                            </div>
                                            <div className="d-block text-right card-footer">
                                                <Link to={`/${product.slug}`} className="btn btn-primary btn-lg">Check the api</Link>
                                            </div>
                                        </div>
                                    </div>
                        })}
                        <div className="col-md-3">
                            <div className="main-card mb-3 card">
                                <div className="card-header card-customize">
                                    <i className="pe-7s-display2 mr-3"> </i>
                                    Ecommerce
                                </div>
                                <div className="card-body">
                                    <p>Integrate online payments in your website</p>
                                </div>
                                <div className="d-block text-right card-footer">
                                    <Link to="/ecommerce" className="btn btn-primary btn-lg">Check the api</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    componentDidMount() {
        this.productDataSource.GetData(
            data => {
                var result = data.content.map(obj => ({
                    ...obj,
                    icons:['pe-7s-rocket', 'pe-7s-world', 'pe-7s-help2', 'pe-7s-box2', 'pe-7s-bookmarks', 'pe-7s-glasses', 'pe-7s-portfolio']
                }));

                this.setState({
                    products: result
                });
            }
        );
    }

}
            
