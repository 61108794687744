import React, { Component } from 'react';


export class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render = () =>
            <div class="d-flex h-100 justify-content-center align-items-center">
                <div class="mx-auto app-login-box col-md-8">
                    <div class="app-logo-inverse mx-auto mb-3">
                    </div>
                    <div class="modal-dialog w-100 mx-auto">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="h5 modal-title text-center">
                                    <h4 class="mt-2">
                                        <div>Welcome back,</div>
                                        <span>Please sign in to your account below.</span>
                                    </h4>
                                </div>
                                <form class="">
                                    <div class="form-row">
                                        <div class="col-md-12">
                                            <div class="position-relative form-group">
                                                <input name="email" id="exampleEmail" placeholder="Email here..." type="email" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="position-relative form-group">
                                                <input name="password" id="examplePassword" placeholder="Password here..." type="password" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="position-relative form-check">
                                        <input name="check" id="exampleCheck" type="checkbox" class="form-check-input" />
                                            <label for="exampleCheck" class="form-check-label">Keep me logged in</label>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer clearfix">
                                <div class="float-right">
                                    <button class="btn btn-primary btn-lg">Login to Dashboard</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center text-white opacity-8 mt-3">Copyright © ArchitectUI 2019</div>
                </div>
            </div>

}
            
