import React, { Component } from 'react';

import { BrowserRouter as Router, Link, Route } from "react-router-dom";

import { RestDataSource } from "../webservice/RestDataSource";

import {LinkComponent} from './LinkComponent';

export class ApiLinkComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allApi: []
        };

        this.productDataSource = new RestDataSource("https://api.afrikpay.com/apis?product="+this.props.product.slug);
    }

    render = () =>
        <React.Fragment>
            {this.state.allApi.map( api => {
                return <LinkComponent key={api.id} api={api}/>
            })}
        </React.Fragment>
        
    componentDidMount() {
        this.productDataSource.GetData(
            data => {
                this.setState({
                    allApi: data.content
                });
            }
        );

    }
}
