import React, { Component } from 'react';

import { BrowserRouter as Router, Link, Route, Redirect } from "react-router-dom";

export class GenericEcommerceApiComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products:[]
        };
    }

    render = () =>
        <React.Fragment>
            <div className="jumbotron-fluid bg-heavy-rain mb-4" data-step="4" data-intro="This is the end of the tour!" data-position="top">
                <div className="container">
                    <h1 className="display-4">Ecommerce Apis</h1>
                    <p className="lead" id="elem-tour-5">Integrate online payment</p>
                </div>
            </div>
            <div className="tab-content">
                <div className="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="main-card mb-3 card">
                                <div className="card-header card-customize">
                                    <i className="pe-7s-display2 mr-3"> </i>
                                    Website Integration
                                </div>
                                <div className="card-body">
                                    <p>Integrate ecommerce apis in the frontend of your website</p>
                                </div>
                                <div className="d-block text-right card-footer">
                                    <Link to="/ecommerce/website" className="btn btn-primary btn-lg">Check the api</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="main-card mb-3 card">
                                <div className="card-header card-customize">
                                    <i className="pe-7s-help2 mr-3"> </i>
                                    WooCommerce Integration
                                </div>
                                <div className="card-body">
                                    <p>Integrate ecommerce apis in your woocommerce website </p>
                                </div>
                                <div className="d-block text-right card-footer">
                                    <Link to="/ecommerce/woocommerce" className="btn btn-primary btn-lg">Check the api</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    componentDidMount() {
    }

}
            
