import React, { Component } from 'react';

import { BrowserRouter as Router, Link, Route } from "react-router-dom";

export class WooCommerceApiComponent extends Component {

    constructor(props) {
        super(props);
        
    }

    downloadWooCommmerce() {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: 'https://www.afrikpay.com/assets/plugins/afrikpay-woocommerce-plugin-master.zip'
            };
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    downloadOrange() {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: 'https://www.afrikpay.com/assets/plugins/afrikpay-orange-money-cm-woocommerce-plugin-master.zip'
            };
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    downloadMtn() {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: 'https://www.afrikpay.com/assets/plugins/afrikpay-mtn-mobile-money-cm-woocommerce-plugin-master.zip'
            };
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }


    downloadEum() {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: 'https://www.afrikpay.com/assets/plugins/afrikpay-eu-mobile-money-woocommerce-plugin-master.zip'
            };
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    render = () =>
        <React.Fragment>
            <div className="jumbotron-fluid bg-heavy-rain" data-step="4" data-intro="This is the end of the tour!" data-position="top">
                <div className="container">
                    <h1 className="display-4">Our api for WooCommerce website</h1>
                    <p className="lead" id="elem-tour-5">Here our api for woocommerce website</p>
                </div>
            </div>

            <div className="app-main__inner p-0">
                <div className="app-inner-layout chat-layout">
                    <div className="app-inner-layout__wrapper row-fluid no-gutters">
                        <div className="col-md-12 app-inner-layout__content card">
                            <div className="pb-md-5 pl-md-5 pr-md-5 pt-md-3">
                                <div className="tab-content">
                                    <div className="tab-pane active show" id="tab-faq-1">
                                        <div id="accordion" className="accordion-wrapper mb-3">
                                            <div className="card">
                                                <div id="headingOne" className="card-header">
                                                    <button type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" className="text-left m-0 p-0 btn btn-link btn-block">
                                                        <h5 className="m-0 p-0">Download WooCommerce Plugin</h5>
                                                    </button>
                                                </div>
                                                <div data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne" className="collapse show">
                                                    <div className="card-body">
                                                        <div className="d-flex flex-md-row flex-column">
                                                            <button className="mb-2 mr-2 btn-icon btn btn-primary" onClick={this.downloadWooCommmerce}>
                                                                <i className="pe-7s-cloud-download btn-icon-wrapper"></i>
                                                                Afrikpay plugin
                                                            </button>
                                                            <button className="mb-2 mr-2 btn-icon btn btn-primary" onClick={this.downloadOrange}>
                                                                <i className="pe-7s-cloud-download btn-icon-wrapper"></i>
                                                                Orange Money plugin &nbsp;
                                                            </button>
                                                            <button className="mb-2 mr-2 btn-icon btn btn-primary" onClick={this.downloadMtn}>
                                                                <i className="pe-7s-cloud-download btn-icon-wrapper"></i>
                                                                MTN Mobile Money plugin &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </button>
                                                            <button className="mb-2 mr-2 btn-icon btn btn-primary" onClick={this.downloadEum}>
                                                                <i className="pe-7s-cloud-download btn-icon-wrapper"></i>
                                                                Express Union Mobile Money plugin &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div id="headingTwo" className="card-header">
                                                    <button type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" className="text-left m-0 p-0 btn btn-link btn-block">
                                                        <h5 className="m-0 p-0">WooCommerce Plugin Github Source Code</h5>
                                                    </button>
                                                </div>
                                                <div data-parent="#accordion" id="collapseTwo" aria-labelledby="headingTwo" className="collapse show">
                                                    <div className="card-body">
                                                        <div className="d-flex flex-md-row flex-column">
                                                            <a className="mb-2 mr-2 btn-icon btn btn-primary" href="https://github.com/afrikpaysas/afrikpay-woocommerce-plugin">
                                                                <i className="pe-7s-vector btn-icon-wrapper"></i>
                                                                Afrikpay
                                                            </a>
                                                            <a className="mb-2 mr-2 btn-icon btn btn-primary" href="https://github.com/afrikpaysas/afrikpay-orange-money-cm-woocommerce-plugin">
                                                                <i className="pe-7s-vector btn-icon-wrapper"></i>
                                                                Orange Mobile Money
                                                            </a>
                                                            <a className="mb-2 mr-2 btn-icon btn btn-primary" href="https://github.com/afrikpaysas/afrikpay-mtn-mobile-money-cm-woocommerce-plugin">
                                                                <i className="pe-7s-vector btn-icon-wrapper"></i>
                                                                MTN Mobile Money
                                                            </a>
                                                            <a className="mb-2 mr-2 btn-icon btn btn-primary" href="https://github.com/afrikpaysas/afrikpay-eu-mobile-money-woocommerce-plugin">
                                                                <i className="pe-7s-vector btn-icon-wrapper"></i>
                                                                Express Union Mobile Money
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5"></div>
                                        <div className="clearfix">
                                            <div className="text-center">
                                                <Link to="/" className="btn-pill btn-wide btn-shadow btn btn-primary btn-lg">Check the other api</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

}

