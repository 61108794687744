import React, { Component } from 'react';

import { BrowserRouter as Router, Link, Route } from "react-router-dom";

import Safe from "react-safe"

import SyntaxHighlighter from 'react-syntax-highlighter';

import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';


export class EcommerceApiComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render = () =>
        <React.Fragment>
            <div className="jumbotron-fluid bg-heavy-rain" data-step="4" data-intro="This is the end of the tour!" data-position="top">
                <div className="container">
                    <h1 className="display-4">Our api for ecommerce website</h1>
                    <p className="lead" id="elem-tour-5">Here you have all our api for ecommerce</p>
                </div>
            </div>

            <div className="app-main__inner p-0">
                <div className="app-inner-layout chat-layout">
                    <div className="app-inner-layout__wrapper row-fluid no-gutters">
                        <div className="col-md-12 app-inner-layout__content card">
                            <div className="pb-md-5 pl-md-5 pr-md-5 pt-md-3">
                                <div className="tab-content">
                                    <div className="tab-pane active show" id="tab-faq-1">
                                        <div id="accordion" className="accordion-wrapper mb-3">
                                            <div className="card">
                                                <div id="headingThree" className="card-header">
                                                    <button type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseOne" className="text-left m-0 p-0 btn btn-link btn-block">
                                                        <h5 className="m-0 p-0">Ecommerce Scenario</h5>
                                                    </button>
                                                </div>
                                                <div data-parent="#accordion" id="collapseThree" aria-labelledby="headingThree" className="collapse show">
                                                    <div className="card-body">
                                                        <div>
                                                            <img src="https://cloud.afrikpay.com/images/diagrams/ecommerce.png" alt="" className="img-fluid"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div id="headingTwo" className="card-header">
                                                    <button type="button" data-toggle="collapse" data-target="#collapseOne2" aria-expanded="false" aria-controls="collapseFour" className="text-left m-0 p-0 btn btn-link btn-block">
                                                        <h5 className="m-0 p-0">
                                                            Form Request Parameters
                                                        </h5>
                                                    </button>
                                                </div>
                                                <div data-parent="#accordion" id="collapseOne2" className="collapse show">
                                                    <div className="card-body">
                                                        <div className="table-responsive">
                                                            <table className="mb-0 table table-bordered">
                                                                <thead className="thead-custom-blue">
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Description</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="table-light">
                                                                        <td>provider</td>
                                                                        <td>The provider of the paiement service. Possible values: <span style={{color:'#3f6ad8'}}>afrikpay|orange_money_cm|mtn_mobilemoney_cm|express_union_mobilemoney</span></td>
                                                                    </tr>
                                                                    <tr className="table-light">
                                                                        <td>merchantid</td>
                                                                        <td>The reference of the merchant account at Afrikpay. It is given by Afrikpay</td>
                                                                    </tr>
                                                                    <tr className="table-light">
                                                                        <td>brand</td>
                                                                        <td>The commercial name of the company visible on the interface.</td>
                                                                    </tr>
                                                                    <tr className="table-light">
                                                                        <td>amount</td>
                                                                        <td>The amount to be paid</td>
                                                                    </tr>
                                                                    <tr className="table-light">
                                                                        <td>purchaseref</td>
                                                                        <td>The internal reference of the paiement on the merchant system.</td>
                                                                    </tr>
                                                                    <tr className="table-light">
                                                                        <td>description</td>
                                                                        <td>The description of the product to be paid</td>
                                                                    </tr>
                                                                    <tr className="table-light">
                                                                        <td>accepturl</td>
                                                                        <td>The url on the merchant system that Afrikpay will hit after a successfull payement</td>
                                                                    </tr>
                                                                    <tr className="table-light">
                                                                        <td>cancelurl</td>
                                                                        <td>The url on the merchant system that Afrikpay will hit after a cancel payment</td>
                                                                    </tr>
                                                                    <tr className="table-light">
                                                                        <td>declineurl</td>
                                                                        <td>The url on the merchant system that Afrikpay will hit after an unsuccessfull payment</td>
                                                                    </tr>
                                                                    <tr className="table-light">
                                                                        <td>language</td>
                                                                        <td>The language on the interface. The default language is english</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div id="headingFour" className="card-header">
                                                    <button type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" className="text-left m-0 p-0 btn btn-link btn-block">
                                                        <h5 className="m-0 p-0">Structure of notif url</h5>
                                                    </button>
                                                </div>
                                                <div data-parent="#accordion" id="collapseFour" aria-labelledby="headingThree" className="collapse show">
                                                    <div className="card-body">
                                                        <div className="alert fade show" role="alert" style={{backgroundColor: '#d6d8db'}}><h5>Method: GET</h5><p className="mb-0"></p></div>
                                                        <div className="table-responsive">
                                                            <table className="mb-0 table table-bordered">
                                                                <thead className="thead-custom-blue">
                                                                <tr>
                                                                    <th>Parameters</th>
                                                                    <th>Description</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr className="table-light">
                                                                    <td>purchaseref</td>
                                                                    <td>the internal reference of the transaction on the merchant side</td>
                                                                </tr>
                                                                <tr className="table-light">
                                                                    <td>amount</td>
                                                                    <td>The amount of the transaction</td>
                                                                </tr>
                                                                <tr className="table-light">
                                                                    <td>status</td>
                                                                    <td>The status of the transaction. Possible values: "OK", "NOK" </td>
                                                                </tr>
                                                                <tr className="table-light">
                                                                    <td>date</td>
                                                                    <td>The date of the transaction on Afrikpay side;  format = Y-m-d H:m:s</td>
                                                                </tr>
                                                                <tr className="table-light">
                                                                    <td>paymentref</td>
                                                                    <td>the internal reference of the transaction on Afrikpay side</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div id="headingThree" className="card-header">
                                                    <button type="button" data-toggle="collapse" data-target="#collapseOne3" aria-expanded="false" aria-controls="collapseOne" className="text-left m-0 p-0 btn btn-link btn-block">
                                                        <h5 className="m-0 p-0">Ecommerce Sample</h5>
                                                    </button>
                                                </div>
                                                <div data-parent="#accordion" id="collapseOne3" aria-labelledby="headingThree" className="collapse show">
                                                    <div className="card-body">
                                                        <div className="mb-3 text-dark card-border card text-white bg-light">
                                                            <div className="card-header">
                                                                <ul className="nav nav-justified">
                                                                    <li className="nav-item"><a data-toggle="tab" href="#tab-eg7-0" className="nav-link active show">Button Display</a></li>
                                                                    <li className="nav-item"><a data-toggle="tab" href="#tab-eg7-1" className="nav-link show">Source Code</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="tab-content">
                                                                    <div className="tab-pane active show" id="tab-eg7-0" role="tabpanel">
                                                                        <div>
                                                                            <style>
                                                                                .pbtn-afp {
                                                                            }
                                                                            </style>
                                                                            <h3>Sample 1</h3>
                                                                            <div id="all-platforms">
                                                                            </div>
                                                                            <br/><br/>
                                                                            <h3>Sample 2</h3>
                                                                            <div id="afrikpay">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-pane show" id="tab-eg7-1" role="tabpanel">
                                                                        &lt;style&gt; <br/>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp; .pbtn-afp {
                                                                        } <br/>
                                                                        &lt;/style&gt; <br/>
                                                                        &lt;h3&gt;Sample 1&lt;/h3&gt; <br/>
                                                                        &lt;div id="all-platforms"&gt; <br/>
                                                                        &lt;/div&gt; <br/>
                                                                        &lt;br>&lt;br> <br/>
                                                                            &lt;h3&gt;Sample 2&lt;/h3&gt; <br/>
                                                                            &lt;div id="afrikpay"&gt; <br/>
                                                                            &lt;/div&gt; <br/>
                                                                            &lt;script src='https://www.afrikpay.com/assets/js/afrik-ecommerce.min.js' &gt;&lt;/script&gt; <br/>
                                                                            &lt;script&gt; <br/>
                                                                                var objectData = {'{'} <br/>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; merchantid: '2823965986107512',  //Required  - Provided by AfrikPay <br/>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; purchaseref: 'EE343EE',  //Required  - Your unique transaction ID <br/>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; amount: 100,  //Required  - Purchase amount <br/>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; phonenumber : '',   //Not required  -  Payer phone number <br/>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; brand: 'AfrikPay eCommerce', //Not required  - <br/>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; text: 'Service eCommerce AfrikPay (Test)',   //Not required <br/>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; accepturl: 'https://www.afrikpay.com/accept',  //Not required <br/>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; cancelurl: 'https://www.afrikpay.com/cancel',  //Not required <br/>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; declineurl: 'https://www.afrikpay.com/decline',  //Not required <br/>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; language: 'en',  //Not required, Default : en <br/>
                                                                            {'}'} <br/>
                                                                                renderBtns('all-platforms', ['afrikpay', 'orange_money_cm', 'mtn_mobilemoney_cm', 'express_union_mobilemoney'], 'pbtn-afp', objectData); <br/>
                                                                                renderBtns('afrikpay', ['afrikpay'], 'pbtn-afp', objectData); <br/>
                                                                            &lt;/script&gt; <br/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5"></div>
                                        <div className="clearfix">
                                            <div className="text-center">
                                                <Link to="/" className="btn-pill btn-wide btn-shadow btn btn-primary btn-lg">Check the other api</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    componentDidMount () {
        const script1 = document.createElement("script");

        script1.src = "https://www.afrikpay.com/assets/js/afrik-ecommerce.min.js";
        script1.async = true;

        document.body.appendChild(script1);

        const script2 = document.createElement("script");

        script2.src = "https://www.afrikpay.com/assets/js/afrik-ecommerce-form.js";
        script2.async = true;

        document.body.appendChild(script2);
    }

}

