import React, { Component } from 'react';

import { BrowserRouter as Router, Link, Route } from "react-router-dom";

export class LinkComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ariaExpanded: false,
            showLink: "mm-collapse"
        };
    }

    handleEvent = () =>  {
        this.setState({
            ariaExpanded: !this.state.ariaExpanded
        });
        this.state.ariaExpanded ? this.state.showLink="mm-collapse mm-show":this.state.showLink="mm-collapse";
        console.log("handleEvent method invoked");
    };

    render = () =>
        <li className="mm-active">
            <Link to={`/documentation/${this.props.api.ref}`}  aria-expanded={this.state.ariaExpanded} onClick={ this.handleEvent }>
                {this.props.api.name}
                <i className=""></i>
            </Link>
            {/*
            <ul className={this.state.showLink}>
                <li>
                    <Link to={`/description/${this.props.api.category.slug}`} className="mm-active">
                        <i className="metismenu-icon">
                        </i>Api description
                    </Link>
                </li>
                <li>
                    <Link to={`/description/${this.props.api.category.slug}`}>
                        <i className="metismenu-icon">
                        </i>Api endpoint
                    </Link>
                </li>
                <li>
                    <Link to={`/description/${this.props.api.category.slug}`}>
                        <i className="metismenu-icon">
                        </i>Api method
                    </Link>
                </li>
                <li>
                    <Link to={`/description/${this.props.api.category.slug}`}>
                        <i className="metismenu-icon">
                        </i>Api request type
                    </Link>
                </li>
                <li>
                    <Link to={`/description/${this.props.api.category.slug}`}>
                        <i className="metismenu-icon">
                        </i>Api request body format
                    </Link>
                </li>
                <li>
                    <Link to={`/description/${this.props.api.category.slug}`}>
                        <i className="metismenu-icon">
                        </i>Api request parameters
                    </Link>
                </li>
                <li>
                    <Link to={`/description/${this.props.api.category.slug}`}>
                        <i className="metismenu-icon">
                        </i>Api response data
                    </Link>
                </li>
                <li>
                    <Link to={`/description/${this.props.api.category.slug}`}>
                        <i className="metismenu-icon">
                        </i>Api error codes
                    </Link>
                </li>
            </ul>
             */}
        </li>
}
