import React, { Component } from 'react';

import { RestDataSource } from "../webservice/RestDataSource";

import { BrowserRouter as Router, Link, Route } from "react-router-dom";

import Loader from 'react-loader-spinner'

export class GenericApiComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allApi: [],
            headerStyles: {
                textTransform: 'none'
            },
            productDescription:"",
            productTitle:"",
            loaderStyles: {
                backgroundColor: '#ffffff',
            },
        };
        this.productDataSource = new RestDataSource("https://api.afrikpay.com/apis/dev?product="+this.props.product);
    }

    Capitalize(str){
        var lowercasetext = str.toLowerCase();
        return lowercasetext.charAt(0).toUpperCase() + lowercasetext.slice(1);
    }

    render() {
        if (this.state.allApi.length > 0 && this.state.productDescription != "" && this.state.productTitle != "") {
            return (
                <React.Fragment>
                    <div className="jumbotron-fluid bg-heavy-rain mb-4" data-step="4" data-intro="" data-position="top">
                        <div className="container">
                            <h1 className="display-4">
                                {this.Capitalize(this.state.productTitle)} Apis
                            </h1>
                            <p className="lead" id="elem-tour-5">
                                {this.state.productDescription}
                            </p>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                            <div className="row">
                                {this.state.allApi.map( api => {
                                    return <div className="col-md-3" key={api.id}>
                                        <div className="main-card mb-3 card">
                                            <div  style={this.state.headerStyles}  className="card-header card-customize" >
                                                <i className="pe-7s-display2 mr-3"> </i>
                                                {api.name}
                                            </div>
                                            <div className="card-body">
                                                <p> {api.shortDescription}</p>
                                            </div>
                                            <div className="d-block text-right card-footer">
                                                <Link to={`/documentation/${api.ref}`} className="btn btn-primary btn-lg">Check the api</Link>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                        <div style={this.state.loaderStyles} className="jumbotron min-vh-100 text-center m-0 d-flex flex-column justify-content-center">
                            <Loader
                                type="ThreeDots"
                                color="#264b8c"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.productDataSource.GetData(
            data => {
                this.setState({
                    allApi: data.content,
                    productDescription: data.content[0].category.description,
                    productTitle: data.content[0].category.name
                });
            }
        );
    }
}
            
