import React, { Component } from 'react';

import {ApiInfoComponent} from './ApiInfoComponent';

export class ApiPageComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            apiObject: {},
            slug1: this.props.match.params.slug1,
            slug2: this.props.match.params.slug2,
            slug3: this.props.match.params.slug3,
            slug4: this.props.match.params.slug4,
            slug5: this.props.match.params.slug5
        };

    }

    render = () =>
        <React.Fragment>
            <div className="jumbotron-fluid bg-heavy-rain" data-step="4" data-intro="This is the end of the tour!" data-position="top">
                <div className="container">
                    <h1 className="display-4">Api presentation</h1>
                    <p className="lead" id="elem-tour-5">In this section you will have a full description of the api</p>
                </div>
            </div>
            <ApiInfoComponent slug1={this.state.slug1} slug2={this.state.slug2} slug3={this.state.slug3} slug4={this.state.slug4} slug5={this.state.slug5}/>
        </React.Fragment>
}

