import React, { Component } from 'react';

import {SidebarComponent} from './SidebarComponent';

import {MainDisplayComponent} from './MainDisplayComponent';

import { BrowserRouter as Router, Link, Route } from "react-router-dom";

import { RestDataSource } from "../webservice/RestDataSource";

import Loader from 'react-loader-spinner'

export class MainComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products:[],
            loaderStyles: {
                backgroundColor: '#ffffff',
            }
        };
        this.dataSource = new RestDataSource("https://api.afrikpay.com/products")
    }

    render() {
        if (this.state.products.length > 0) {
            return (
                <Router>
                    <SidebarComponent products={this.state.products}/>
                    <MainDisplayComponent/>
                </Router>
            )
        }
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                        <div  style={this.state.loaderStyles} className="jumbotron min-vh-100 text-center m-0 d-flex flex-column justify-content-center">
                            <Loader
                                type="ThreeDots"
                                color="#264b8c"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.dataSource.GetData(
            data => {
                this.setState({ products: data.content});
            }
        );
    }
    
}
            
