import React, { Component } from 'react';

import { BrowserRouter as Router, Link, Route } from "react-router-dom";

import { RestDataSource } from "../webservice/RestDataSource";

import {ProductLinkComponent} from './ProductLinkComponent';

import { Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

export class SidebarComponent extends Component {

        constructor(props) {
            super(props);
            this.state = {
                products:[],
                mainLinkStyles: {
                    color: '#ffffff',
                    background: '#264b8c'
                },
                style1: {
                    left: '0px',
                    bottom:'-356px'
                },
                style2: {
                    left: '0px',
                    width:'0px'
                },
                style3: {
                    top:'405px',
                    height: '429px',
                    right:'0px'
                },
                style4: {
                    top:'209px',
                    height: '220px'
                },
                icons:['pe-7s-rocket', 'pe-7s-world', 'pe-7s-help2', 'pe-7s-box2', 'pe-7s-bookmarks']
            };
            this.productDataSource = new RestDataSource("https://api.afrikpay.com/products");
        }

        icons = ['pe-7s-rocket', 'pe-7s-world', 'pe-7s-help2', 'pe-7s-box2', 'pe-7s-bookmarks'];

        render = () =>
            <div className="app-sidebar sidebar-shadow sidebar-text-light">
                <div className="app-header__logo">
                    <div className="logo-src"></div>
                    <div className="header__pane ml-auto">
                        <div>
                            <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="app-header__mobile-menu">
                    <div>
                        <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                        </button>
                    </div>
                </div>
                <div className="app-header__menu">
                        <span>
                        <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                            <span className="btn-icon-wrapper">
                                <i className="fa fa-ellipsis-v fa-w-6"></i>
                            </span>
                        </button>
                        </span>
                </div>
                <div className="scrollbar-sidebar ps ps--active-y ps--scrolling-y">
                    <div className="app-sidebar__inner">
                        <Element name="test7" className="element" id="containerElement" style={{
                            position: 'relative',
                            height: '700px',
                            overflow: 'scroll',
                            marginBottom: '100px'
                          }}>
                            <ul className="vertical-nav-menu metismenu">
                                <li className="app-sidebar__heading">
                                    <Link
                                        to="/ecommerce"
                                        style={this.state.mainLinkStyles}
                                    >
                                        <i className="pe-7s-display2 mr-3"> </i>
                                        Ecommerce
                                    </Link>
                                </li>
                                <li className="mm-active">
                                    <a aria-expanded="false" href="/ecommerce/website">
                                        website
                                        <i className=""></i>
                                    </a>
                                </li>
                                <li className="mm-active">
                                    <a aria-expanded="false" href="/ecommerce/woocommerce">
                                        woocommerce
                                        <i className=""></i>
                                    </a>
                                </li>
                                {this.state.products.map( product => {
                                    return <ProductLinkComponent key={product.id} product={product}/>
                                })}
                            </ul>

                        </Element>
                    </div>
                </div>
            </div>

           componentDidMount() {
               Events.scrollEvent.register('begin', function(to, element) {
                   console.log("begin", arguments);
               });

               Events.scrollEvent.register('end', function(to, element) {
                   console.log("end", arguments);
               });

               scrollSpy.update();

               this.productDataSource.GetData(
                   data => {
                       var result = data.content.map(obj => ({
                           ...obj,
                           icons:['pe-7s-rocket', 'pe-7s-world', 'pe-7s-help2', 'pe-7s-box2', 'pe-7s-bookmarks', 'pe-7s-glasses', 'pe-7s-portfolio']
                       }));

                       this.setState({
                           products: result
                       });
                   }
               );
           }

                componentWillUnmount() {
                Events.scrollEvent.remove('begin');
                Events.scrollEvent.remove('end');
            }
                scrollToTop() {
                scroll.scrollToTop();
            }
                scrollToBottom() {
                scroll.scrollToBottom();
            }
                scrollTo() {
                scroll.scrollTo(100);
            }
                scrollMore() {
                scroll.scrollMore(100);
            }
                handleSetActive(to) {
                console.log(to);
            }
    }


