import React from 'react';
import './App.css';
import {HeaderComponent} from './components/HeaderComponent';
import {MainComponent} from './components/MainComponent';

function App() {

  return (
      <div className="App">
        <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
          <HeaderComponent/>
          <div className="app-main">
            <MainComponent/>
          </div>
        </div>
      </div>

  );
}

export default App;
