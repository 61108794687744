import React, { Component } from 'react';

import { RestDataSource } from "../webservice/RestDataSource";

import { BrowserRouter as Router, Link, Route } from "react-router-dom";

import Loader from 'react-loader-spinner';

import SyntaxHighlighter from 'react-syntax-highlighter';

import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Tabs from 'react-bootstrap/Tabs';

import Tab from 'react-bootstrap/Tab';

export class ApiInfoComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allApi: [],
            loaderStyles: {
                backgroundColor: '#ffffff'
            },
        };

        this.apiDataSource = new RestDataSource("https://api.afrikpay.com/apis/dev?product="+this.props.slug1);
    }

    templateDisplay(api){
        return <div className="tab-pane active show" id="tab-faq-1" key={api.id}>
            <div id="accordion" className="accordion-wrapper mb-3">
                <div className="card">
                    <div id="headingOne" className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne" className="text-left m-0 p-0 btn btn-link btn-block">
                            <h5 className="m-0 p-0">Description</h5>
                        </button>
                    </div>
                    <div data-parent="#accordion" id="collapseOne1" aria-labelledby="headingOne" className="collapse show">
                        <div className="card-body">
                            <div dangerouslySetInnerHTML={{__html: api.doc}} />
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div id="headingTwo" className="b-radius-0 card-header">
                        <button type="button" data-toggle="collapse" data-target="#collapseOne2" aria-expanded="false" aria-controls="collapseTwo" className="text-left m-0 p-0 btn btn-link btn-block">
                            <h5 className="m-0 p-0">
                                Endpoint
                            </h5>
                        </button>
                    </div>
                    <div data-parent="#accordion" id="collapseOne2" className="collapse">
                        <div className="card-body">
                            http://35.204.26.22:8086/api/{api.ref}
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div id="headingThree" className="b-radius-0 card-header">
                        <button type="button" data-toggle="collapse" data-target="#collapseOne3" aria-expanded="false" aria-controls="collapseThree" className="text-left m-0 p-0 btn btn-link btn-block">
                            <h5 className="m-0 p-0">
                                Method
                            </h5>
                        </button>
                    </div>
                    <div data-parent="#accordion" id="collapseOne3" className="collapse">
                        <div className="card-body">
                            {api.methodin}
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div id="headingSix" className="b-radius-0 card-header">
                        <button type="button" data-toggle="collapse" data-target="#collapseOne4" aria-expanded="false" aria-controls="collapseSix" className="text-left m-0 p-0 btn btn-link btn-block">
                            <h5 className="m-0 p-0">
                                Request type
                            </h5>
                        </button>
                    </div>
                    <div data-parent="#accordion" id="collapseOne4" className="collapse">
                        <div className="card-body">
                            {api.techno.name}
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div id="headingSeven" className="b-radius-0 card-header">
                        <button type="button" data-toggle="collapse" data-target="#collapseOne5" aria-expanded="false" aria-controls="collapseSeven" className="text-left m-0 p-0 btn btn-link btn-block">
                            <h5 className="m-0 p-0">
                                Request body format
                            </h5>
                        </button>
                    </div>
                    <div data-parent="#accordion" id="collapseOne5" className="collapse">
                        <div className="card-body">
                            {api.bodyFormat.toUpperCase()}
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div id="headingFour" className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#collapseOne6" aria-expanded="false" aria-controls="collapseFour" className="text-left m-0 p-0 btn btn-link btn-block">
                            <h5 className="m-0 p-0">
                                Request Parameters
                            </h5>
                        </button>
                    </div>
                    <div data-parent="#accordion" id="collapseOne6" className="collapse">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="mb-0 table table-bordered">
                                    <thead className="thead-custom-blue">
                                    <tr>
                                        <th>Name</th>
                                        <th>Required</th>
                                        <th>type</th>
                                        <th>description</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {api.resquestData.sort(function(x, y){
                                        return y.required - x.required;
                                    }).map(param => {
                                        if(param.required == true){
                                            return <tr key={param.id} className="table-light">
                                                <td>{param.inName}</td>
                                                <td>{param.required.toString()}</td>
                                                <td>{param.type}</td>
                                                <td>{param.description}</td>
                                            </tr>
                                        }else{
                                            return <tr key={param.id} className="">
                                                <td>{param.inName}</td>
                                                <td>{param.required.toString()}</td>
                                                <td>{param.type}</td>
                                                <td>{param.description}</td>
                                            </tr>
                                        }
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div id="headingSeven" className="b-radius-0 card-header">
                        <button type="button" data-toggle="collapse" data-target="#collapseSeven7" aria-expanded="false" aria-controls="collapseTwo" className="text-left m-0 p-0 btn btn-link btn-block">
                            <h5 className="m-0 p-0">
                                Code Samples
                            </h5>
                        </button>
                    </div>
                    <div data-parent="#accordion" id="collapseSeven7" className="collapse">
                        <div className="card-body">
                            <Tabs defaultActiveKey={api.codesample[0].language} id="uncontrolled-tab-example">
                                {this.codeSample(api.codesample).map(code => {
                                    return  <Tab eventKey={code.language} title={code.language} key={code.language}>
                                        <SyntaxHighlighter language={code.language} style={a11yLight} showLineNumbers={true} wrapLines={true}>
                                            {code.code}
                                        </SyntaxHighlighter>
                                    </Tab>
                                })}
                            </Tabs>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div id="headingFour" className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#collapseOne8" aria-expanded="false" aria-controls="collapseFour" className="text-left m-0 p-0 btn btn-link btn-block">
                            <h5 className="m-0 p-0">
                                Response Data
                            </h5>
                        </button>
                    </div>
                    <div data-parent="#accordion" id="collapseOne8" className="collapse">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="mb-0 table table-bordered">
                                    <thead className="thead-custom-blue">
                                    <tr>
                                        <th>Name</th>
                                        <th>type</th>
                                        <th>description</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {api.responseData.map(param => {
                                        return <tr key={param.id} className="table-light">
                                            <td>{param.inName}</td>
                                            <td>{param.type}</td>
                                            <td>{param.description}</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div id="headingSix" className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#collapseOne7" aria-expanded="false" aria-controls="collapseSix" className="text-left m-0 p-0 btn btn-link btn-block">
                            <h5 className="m-0 p-0">
                                Error codes
                            </h5>
                        </button>
                    </div>
                    <div data-parent="#accordion" id="collapseOne7" className="collapse">
                        <div className="card-body">
                            <div className="alert alert-danger fade show" role="alert">
                                <h5>Erreur 500: Api Error</h5>
                                <p className="mb-0"></p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5"></div>
            <div className="clearfix">
                <div className="text-center">
                    <Link to="/" className="btn-pill btn-wide btn-shadow btn btn-primary btn-lg">Check the other api</Link>
                </div>
            </div>
        </div>
    }

    codeSample(data){
        let all_codes = [];

        if(data == ""){
            return "";
        }

        if(data != ""){
            let blockcodes = data.split("#####");
            let templates = [];
            for (let i = 1; i < blockcodes.length; i++) {
                let blockcode = blockcodes[i].split("*****");
                let language = blockcode[0];
                let code = blockcode[1];
                let object = new Object();
                object.language = language.toLowerCase();
                object.ref = language.toLowerCase();
                object.code = code;
                let href = `#${language.toLowerCase()}`;
                object.href = href.replace(/ +/g, "");
                all_codes.push(object);
            }

            return all_codes;
        }

    }

    render() {
        if (this.state.allApi.length > 0) {
            return (
                <div className="app-main__inner p-0">
                    <div className="app-inner-layout chat-layout">
                        <div className="app-inner-layout__wrapper row-fluid no-gutters">
                            <div className="col-md-12 app-inner-layout__content card">
                                <div className="pb-md-5 pl-md-5 pr-md-5 pt-md-3">
                                    <div className="tab-content">
                                        {this.state.allApi.map(api => {
                                            if(api.ref === `${this.props.slug1}/` && this.props.slug2==null && this.props.slug3==null && this.props.slug4==null && this.props.slug5==null){
                                                return this.templateDisplay(api)
                                            }
                                        })}
                                        {this.state.allApi.map(api => {
                                            if(api.ref === `${this.props.slug1}/${this.props.slug2}/` && this.props.slug3 == null && this.props.slug4==null && this.props.slug5==null){
                                                return this.templateDisplay(api)
                                            }
                                        })}
                                        {this.state.allApi.map(api => {
                                            if(api.ref === `${this.props.slug1}/${this.props.slug2}/${this.props.slug3}/` && this.props.slug4==null && this.props.slug5==null){
                                                return this.templateDisplay(api)
                                            }
                                        })}
                                        {this.state.allApi.map(api => {
                                            if(api.ref === `${this.props.slug1}/${this.props.slug2}/${this.props.slug3}/${this.props.slug4}/` && this.props.slug5==null){
                                                return this.templateDisplay(api)
                                            }
                                        })}
                                        {this.state.allApi.map(api => {
                                            if(api.ref === `${this.props.slug1}/${this.props.slug2}/${this.props.slug3}/${this.props.slug4}/${this.props.slug5}`){
                                                return this.templateDisplay(api)
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                        <div  style={this.state.loaderStyles} className="jumbotron min-vh-100 text-center m-0 d-flex flex-column justify-content-center">
                            <Loader
                                type="ThreeDots"
                                color="#264b8c"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {

        this.apiDataSource.GetData(
            data => {
                this.setState({
                    allApi: data.content
                });
            }
        );
    }
}



