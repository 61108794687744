import React, { Component } from 'react';

import { BrowserRouter as Router, Link, Route } from "react-router-dom";

import {ApiLinkComponent} from './ApiLinkComponent';

import { RestDataSource } from "../webservice/RestDataSource";

export class ProductLinkComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allApi: [],
            mainLinkStyles: {
                color: '#ffffff',
                background: '#264b8c'
            }
        };
        this.apiDataSource = new RestDataSource("https://api.afrikpay.com/apis?product="+this.props.product.slug);
    }

    render = () =>
       <React.Fragment>
           <li className="app-sidebar__heading">
               <Link
                   to={`/${this.props.product.slug}`}
                   style={this.state.mainLinkStyles}
               >
                   <i className={`${this.props.product.icons[this.props.product.id]} mr-3`}> </i>
                   {this.props.product.name}
               </Link>
           </li>
            <ApiLinkComponent product={this.props.product}/> 
       </React.Fragment>

    componentDidMount() {
        this.apiDataSource.GetData(
            data => {
                this.setState({
                    allApi: data.content
                });
            }
        );
    }
}
